<template>
  <v-layout
    class="splash-screen-wrapper"
    fill-height
    align-center
    justify-center
  >
    <v-img
      :src="require('@/assets/criptospace/logotype.png')"
      max-width="10rem"
    />
  </v-layout>
</template>

<script>
export default {
  name: "SplashScreen",
};
</script>

<style src="./style.scss" lang="scss" scoped />
